<template>
  <div>
    <b-container class="m-0 w-100" fluid>
      <header-slot />
      <b-nav card-header pills class="m-0">
        <b-nav-item v-for="item in items" :key="item.id" v-b-tooltip.hover.top="item.tooltip"
          :to="{ name: item.routeName }" :active="$route.matched[2].name === item.routeName"
          exact-active-class="active" :link-classes="['px-3', bgTabsNavs]" class="text-uppercase">
          {{ item.description }} 
          <span v-if="item.id === 2" class="ml-2">
            <feather-icon
              icon
              :badge="S_COUNTER_SELLERS > 99 ? '99+' : S_COUNTER_SELLERS"
              badge-classes="badge-important"
            />
          </span>
          <span v-if="item.id === 1" class="ml-2">
            <feather-icon
              icon
              :badge="counterPermission > 99 ? '99+' : counterPermission"
              badge-classes="badge-important"
            />
          </span>
          <span v-if="item.id === 3" class="ml-2">
            <feather-icon
                icon
                :badge="S_SETTLEMENTS_COUNT > 99 ? '99+' : S_SETTLEMENTS_COUNT"
                badge-classes="badge-important"
            />
          </span>

          <span v-if="item.id === 4" class="ml-2">
            <feather-icon
              icon
              :badge="counterOtherPayment > 99 ? '99+' : counterOtherPayment"
              badge-classes="badge-important"
            />
          </span>
          <span v-if="item.id === 5" class="ml-2">
            <feather-icon
              icon
              :badge="G_COUNTER_DISAPPROVED_SALE > 99 ? '99+' : G_COUNTER_DISAPPROVED_SALE"
              badge-classes="badge-important"
            />
          </span>
        </b-nav-item>
      </b-nav>
      <template>
        <b-card class="border-top-primary border-3 border-table-radius px-0">
          <router-view :key="$route.name" />
        </b-card>
      </template>
    </b-container>
  </div>
</template>

<script>
import data from "./data/db.json";
import SalesSellerServices from "@/views/ce-digital/sub-modules/settings/views/permissions/service/sales-seller.services";
import SettingsService from "@/views/management/views/approval-process/permissions/service/settings.service";
import otherPaymentsService from "@/views/management/views/approval-process/other-payments/other-payments.service";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Approved",
  data() {
    return {
      items: data,
      counterPermission: 0,
      counterOtherPayment: 0,
    };
  },
  async created() {
    await this.getCounterSellerPending();
    await this.getCounterPermission();
    await this.getCounterOtherPayment();
    await this.getCounterSettlements();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      S_COUNTER_SELLERS: "SellerPermissionsStore/S_COUNTER_SELLERS",
      G_COUNTER_DISAPPROVED_SALE: "SellerPermissionsStore/G_COUNTER_DISAPPROVED_SALE",
      S_SETTLEMENTS_COUNT: "DebtSolutionClients/G_SETTLEMENTS_COUNT",
    }),
  },

  methods: {
    ...mapActions({
      GET_COUNTER_SELLERS: "SellerPermissionsStore/GET_COUNTER_SELLERS",
      A_GET_SETTLEMENT_COUNT: "DebtSolutionClients/A_GET_SETTLEMENT_COUNT",
    }),
    async getCounterSellerPending() {
      const params = {
        _status: 1,
      };
      await this.GET_COUNTER_SELLERS(params);
    },
    async getCounterPermission() {
      const res = await SettingsService.getCountPermissionPending();
      this.counterPermission = res.data[0].pending;
    },

    async getCounterOtherPayment() {
      const res = await otherPaymentsService.getCeOtherPaymentsPendingCounter();
      this.counterOtherPayment = res.data;
    },
 

    async getCounterSettlements() {
      const params = {
        accountId: null,
        user_id: this.currentUser.user_id,
        module_id: this.$route.matched[0].meta.module
      }
      const res = await this.A_GET_SETTLEMENT_COUNT(params);
    },
  },
};
</script>

<style scoped>
</style>
